import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisIssueFilters from "@/components/issue-filters"
import { getFootersForTable } from "@/utils/table"
import {
  DATA_EXPORT_STATUS,
  FIELD_TYPES,
  ISSUE_TYPES,
  ISSUE_SEARCH_STATUS,
  ISSUE_SEARCH_POLL_INTERVAL,
  TABLE_NAMES,
  SCREEN_ITEM_DISPLAY_PROPERTIES,
  DEFAULT_DATA_RETENTION_PERIODS
} from "@/constants"
import ThemisIssuesColumnsPreference from "@/components/issues-columns-preference"
import ThemisBulkImportCases from "@/components/bulk-import-cases"
import ThemisScreen from "@/components/screen"
import { convertDaysToDuration } from "@/utils"

export default {
  name      : "Issues",
  components: {
    ThemisIssueFilters,
    ThemisIssuesColumnsPreference,
    ThemisBulkImportCases,
    ThemisScreen
  },
  data() {
    return {
      showAddIssueDialog          : false,
      domainId                    : null,
      localIssuesFiltersPreference: {},
      localIssuesSortingPreference: null,
      screenItemsForComponent     : [],
      issueTypes                  : [{
        header: this.$t("1272")
      },
      {
        name : ISSUE_TYPES[0].name,
        value: ISSUE_TYPES[0].id
      }],
      allFilters                : new Array(),
      filterCriteria            : {},
      kpiFilterCriteria         : new Array(),
      issueAnonymisationStatuses: [
        { name: this.$t("632"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.NOT_SCHEDULED },
        { name: this.$t("633"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.SCHEDULED },
        { name: this.$t("634"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.ANONYMISED }
      ],
      reportStatuses: [
        { name: this.$t("636"), value: this.$CONSTANTS.REPORT_STATUS.CLOSED },
        { name: this.$t("635"), value: this.$CONSTANTS.REPORT_STATUS.NEW }
      ],
      reportSources: [
        { name: this.$t("1162"), value: this.$CONSTANTS.REPORT_SOURCE.WEB },
        { name: this.$t("1163"), value: this.$CONSTANTS.REPORT_SOURCE.APP },
        { name: this.$t("1164"), value: this.$CONSTANTS.REPORT_SOURCE.PHONE }
      ],
      messageStatuses: [
        { name: this.$t("2067"), value: this.$CONSTANTS.MESSAGE_FILTER_STATUS.READ },
        { name: this.$t("2068"), value: this.$CONSTANTS.MESSAGE_FILTER_STATUS.UNREAD }
      ],
      showIssuesColumnsPreference: false,
      searchText                 : null,
      searchThroughAllIssueData  : false,
      issueSearchPollingInterval : null,
      showBulkImportCasesDialog  : false
    }
  },
  beforeDestroy() {
    this.clearIssueSearchPollingInterval()
  },
  methods: {
    ...mapActions({
      addExport                      : "exports/addExport",
      updateUser                     : "users/updateUser",
      loadIssueSearch                : "issueSearch/loadIssueSearch",
      loadIssueSearchWithCriteria    : "issueSearch/loadIssueSearchWithCriteria",
      loadIssuesFromLastSearch       : "issues/loadIssuesFromLastSearch",
      notify                         : "shared/notify",
      makeTransition                 : "transitions/makeTransition",
      uploadCsvFilesToBulkImportCases: "issues/uploadCsvFilesToBulkImportCases"
    }),
    ...mapMutations({
      setProgressBarPromisesPending: "shared/setProgressBarPromisesPending"
    }),
    userHasAccessToTheDomain(domainId) {
      return this.allowedIssuesAndDomainsForIssueViewPolicy.domainIds.includes(domainId)
    },
    async addNewIssue() {
      this.showAddIssueDialog      = true
      let screenItems              = this.initialTransitionCase.screen
        ? [...this.initialTransitionCase.screen.screenItems].filter(screenItem => screenItem.key !== "domainId")
        : []
      screenItems                  = [{
        key         : "domainId",
        mandatory   : true,
        sortingOrder: 0,
        fields      : []
      }, ...screenItems]
      this.screenItemsForComponent = screenItems
        .sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)
        .map(screenItem => {
          let issueFieldForScreen
          if (screenItem && screenItem.fields.length) {
            const [screenItemField] = screenItem.fields
            const field             = this.fieldsMap[screenItemField.id]
            const issueFieldId      = this.issueFields.find(issueField => issueField.fieldId === screenItemField.id)?.id
            if (field) {
              issueFieldForScreen = {
                fieldId             : field.id,
                issueFieldId        : issueFieldId,
                fieldType           : field.type,
                fieldWidget         : field.widget,
                fieldLabel          : screenItem.mandatory ? `${field.label}*` : field.label,
                optionListId        : field.optionListId,
                isOptionListCascaded: this.isOptionListCascaded(field),
                issueFieldValues    : this.getIssueFieldValuesForAParticularIssueField(field)
              }
            }
            issueFieldForScreen = {
              ...issueFieldForScreen,
              optionListItems: this.getOptionListItemsForAParticularIssueField(issueFieldForScreen),
              readOnly       : this.isReadOnlyIssueFields()
            }
          }
          return {
            key      : screenItem.key,
            mandatory: screenItem.mandatory,
            items    : this[SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key]?.computedPropertyValue],
            field    : issueFieldForScreen
          }
        })
    },
    isOptionListCascaded(issueField) {
      const filteredOptionListItems = this.optionListItems.filter(optionList =>
        optionList.optionListId === issueField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    getIssueFieldValuesForAParticularIssueField(issueField) {
      return this.issueFieldValues
        .filter(issueFieldValue =>
          issueFieldValue.issueFieldId === issueField.issueFieldId
        )
    },
    getOptionListItemsForAParticularIssueField(issueField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value]
        .includes(issueField?.fieldType)) {
        const sortedOptionListItems = this.optionListItems
          .filter(optionListItem => optionListItem.optionListId === issueField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple              = this.isFieldTypeMultipleOptionList(issueField)
        const options                 = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)
        const currentIssueFieldValues = this.getIssueFieldValuesForAParticularIssueField(issueField)

        for (const issueFieldValue of currentIssueFieldValues) {
          const isOptionListItemExists = sortedOptionListItems.find(option => issueFieldValue.value === option.name)
          if (!isOptionListItemExists && issueFieldValue.value) {
            options.push({ name: issueFieldValue.value })
          }
        }
        return options
      }
    },
    isFieldTypeMultipleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })
      return result
    },
    isReadOnlyIssueFields() {
      return false
    },
    filterIssues() {
      const query = {}
      for (const filter of this.kpiFilterCriteria) {
        if (filter === 0) {
          query.unread = true
        } else if(filter === 1) {
          query.unanswered = true
        }
      }
      this.$router.replace({
        name: this.$route.name,
        query
      })
    },
    updateUserPreferenceForRowsPerPage(itemsPerPage) {
      this.updateUser({ id: this.loggedInUser.id, issuesRowsPerPagePreference: itemsPerPage })
    },
    /**
     * utility to generate search criteria which can be directly queried on issues table or store
     * @param {*} filters - contains all the filters made by user on issues
     */
    generateSearchCriteria(filters) {
      this.filterCriteria = {}
      for (const filter of filters) {
        const entityName    = filter.key[0]
        const entityId      = filter.key[1]
        const updatedFilter = {}
        if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE) {
          const formFieldIndex      = Object.keys(this.filterCriteria).filter(criteria =>
            criteria.indexOf(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE) !== -1).length / 2
          const fieldId             = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE.concat("[").concat(formFieldIndex).concat("].fieldId")
          const fieldValue          = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE.concat("[").concat(formFieldIndex).concat("].formInstanceFieldValues[0].value")
          updatedFilter[fieldId]    = filter.key[2].fieldId
          updatedFilter[fieldValue] = [...filter.values]
        } else if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) {
          const issueFieldIndex = Object.keys(this.filterCriteria).filter(criteria =>
            criteria.indexOf(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) !== -1).length / 2

          const issueFieldId    = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].issueFieldId")
          const issueFieldValue = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].value")

          updatedFilter[issueFieldId]    = filter.key[2].issueFieldId
          updatedFilter[issueFieldValue] = [...filter.values]
        } else {
          switch (entityName) {
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]:
              updatedFilter[entityId] = [filter.values[0].concat("T00:00:00"), filter.values[1].concat("T23:59:59")].join("to")
              break
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0]:
              updatedFilter[entityId] = [
                this.$moment(filter.values[0] + "T00:00:00").utc().toISOString(),
                this.$moment(filter.values[1] + "T23:59:59").utc().toISOString()
              ].join("to")
              break
            default:
              updatedFilter[entityId] = [...filter.values]
              break
          }
        }
        this.filterCriteria = Object.assign({}, this.filterCriteria, updatedFilter)
      }
    },
    /**
     * This method converts the flattened json with dot notation into its expanded representation
     * Eg. {"report[0].channel": 2, "issueStatuses": [23, 34]}
     *      will be resolved to
     *  {
     *    "report": [{
     *      "channel": 2
     *    }],
     *    "issueStatuses": [23, 34]
     *  }
     * unflattenJson will point to the root of object(at starting it will be {}), tempObject will point inside array or array of object(it goes into deeper layer like inside array of object and inside that object)
     * We split the key of filterCriteria("report[0].channel") and checks whether it is an array("report[0]") or an object key("channel")
     * If it's an array we create a new array with empty object with index('0' for "report[0]") only if there is no same array name present in the same layer
     * If its an object key, we create an object key and assign the value to it("2") only if its last index of split result
     * At the last index of split result, in the end we make the tempObject point to the root of unflattenJson
     */
    generateUnflattenJson() {
      const arrayElementRegex = /^[a-zA-Z]+\[[0-9]+\]$/
      const unflattenJson     = {}
      let tempObject          = unflattenJson
      for (const fullyQualifiedEntityName of Object.keys(this.filterCriteria)) {
        fullyQualifiedEntityName.split(".").map((key, index, entityNames) => {
          let arrayIndex = -1
          if (index === entityNames.length - 1) {
            tempObject[key] = this.filterCriteria[fullyQualifiedEntityName]
          } else if (arrayElementRegex.test(key)) {
            const startArrayIndex = key.indexOf("[")
            const endArrayIndex   = key.indexOf("]")
            arrayIndex            = parseInt(key.substring(startArrayIndex + 1, endArrayIndex))
            key                   = key.substring(0, startArrayIndex)
            if (!tempObject[key]) {
              tempObject[key]             = []
              tempObject[key][arrayIndex] = {}
            } else if (!tempObject[key][arrayIndex]){
              tempObject[key][arrayIndex] = {}
            }
          } else {
            tempObject[key] = {}
          }
          if (arrayIndex !== -1) {
            tempObject = tempObject[key][arrayIndex]
          } else {
            tempObject = unflattenJson
          }
        })
      }
      return unflattenJson
    },
    /**
     * Whenever we apply filter for a field in issue, that field along with chosen value is maintained in an array and API call is made.
     * For changes on properties apart from first element of array, the filter is applied based on previous results fetched from API.
     * Whenever all the filtered values of first element of array is removed, then the corresponding entry is removed from array
     * And all the elements in array is shifted by one position. Subsequent API calls will be made, only on changes of new first element
     * @param {*} entity - array having filter name and corresponding field name in issue table. For form fields, this array will also include form field id as third element in array
     * @param {*} values - values filtered for selected field
     */
    applyFilter(entity, values) {
      if (entity && values) {
        if (!this.allFilters.length && values.length) {
          if(!this.allFilters.find(filter => filter.key[0] === entity[0])) {
            this.allFilters.push({ key: entity, values: values })
          }
        } else {
          const filterIndex = this.allFilters.findIndex(filter =>
            filter.key[0] === entity[0] &&
            filter.key[1] === entity[1]
          )
          if (filterIndex === -1 && values.length) {
            this.allFilters.push({ key: entity, values: values })
          } else {
            if (values.length) {
              this.allFilters.splice(filterIndex, 1, { key: entity, values: values })
            } else {
              this.allFilters = this.allFilters.filter(filter =>
                filter.key[0] !== entity[0] ||
                filter.key[1] !== entity[1]
              )
            }
          }
        }
      } else {
        this.allFilters = []
      }

      this.generateSearchCriteria(this.allFilters)
      this.localIssuesFiltersPreference = this.generateUnflattenJson()
    },
    handleSaveIssuesColumnsPreference(issuesColumnsPreference) {
      this.updateUser({
        id                     : this.loggedInUser.id,
        issuesColumnsPreference: issuesColumnsPreference
      })
    },
    exportIssues(type) {
      this.addExport({
        type
      })
    },
    clearIssueSearchPollingInterval() {
      if (this.issueSearchPollingInterval) {
        clearInterval(this.issueSearchPollingInterval)
        this.issueSearchPollingInterval = null
      }
    },
    handleIssueSearch() {
      if (
        !this.isLoadingIssues
        && this.isSearchCriteriaChanged
        && this.isIssueSearchCompleted
      ) {
        this.loadIssueSearchWithCriteria({
          criteria: {
            searchText               : this.searchText,
            issuesFilters            : this.localIssuesFiltersPreference,
            searchThroughAllIssueData: this.searchThroughAllIssueData
          }
        })
      }
    },
    handleCancelAddIssue() {
      this.showAddIssueDialog      = false
      this.screenItemsForComponent = []
    },
    handleSubmitAddIssue(screenItems) {
      this.domainId          = screenItems.domainId
      const [transitionLink] = this.initialTransitionCase.transitionLinks
      this.makeTransition({
        id  : this.initialTransitionCase.id,
        data: {
          ...screenItems,
          typeId  : this.issueTypeCase.id,
          statusId: transitionLink.toStatusId
        }
      })
    },
    updateUserColumnsSortingPreference(updateOptions) {
      const sortBy             = this.loggedInUserIssuesColumnsSortingPreference.sortBy
      const sortDesc           = this.loggedInUserIssuesColumnsSortingPreference.sortDesc
      const hasSortByChanged   = JSON.stringify(sortBy) !== JSON.stringify(updateOptions.sortBy)
      const hasSortDescChanged = JSON.stringify(sortDesc) !== JSON.stringify(updateOptions.sortDesc)

      if (hasSortByChanged || hasSortDescChanged) {
        this.localIssuesSortingPreference = {
          sortBy  : updateOptions.sortBy,
          sortDesc: updateOptions.sortDesc
        }
      }
    },
    handleCancelBulkImportCases() {
      this.showBulkImportCasesDialog = false
    },
    handleBulkImportCases(dataToUpdated){
      this.showBulkImportCasesDialog = false
      this.uploadCsvFilesToBulkImportCases(dataToUpdated)
    }
  },
  computed: {
    ...mapGetters({
      issues                                    : "issues/issues",
      lastSearchIssues                          : "issues/lastSearchIssues",
      isLoadingIssues                           : "issues/isLoadingIssues",
      domains                                   : "domains/domains",
      issueStatuses                             : "issueStatuses/issueStatuses",
      issueResolutions                          : "issueResolutions/issueResolutions",
      users                                     : "users/users",
      usersIncludingDeletedUsers                : "users/usersIncludingDeletedUsers",
      channels                                  : "channels/channels",
      labels                                    : "labels/labels",
      loggedInUserPolicies                      : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages                  : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport                : "kpis/issuesWithUnansweredReport",
      optionListItems                           : "optionListItems/optionListItems",
      fields                                    : "fields/fieldsV2",
      formTemplates                             : "formTemplates/formTemplates",
      formTemplateConfigurations                : "formTemplateConfigurations/formTemplateConfigurations",
      loggedInUser                              : "auth/loggedInUser",
      issueFields                               : "issueFields/issueFields",
      loggedInUserIssuesColumnsPreference       : "users/loggedInUserIssuesColumnsPreference",
      isUpdatingIssuesColumnsPreference         : "users/isUpdatingIssuesColumnsPreference",
      isIssuesColumnsPreferenceUpdated          : "users/isIssuesColumnsPreferenceUpdated",
      loggedInUserIssuesRowsPerPagePreference   : "users/loggedInUserIssuesRowsPerPagePreference",
      issueFieldValues                          : "issueFieldValues/issueFieldValues",
      isLoadingIssueFieldValues                 : "issueFieldValues/isLoadingIssueFieldValues",
      languages                                 : "languages/languages",
      isAddingExport                            : "exports/isAddingExport",
      latestIssuesExport                        : "exports/latestIssuesExport",
      loggedInUserIssuesFiltersPreference       : "users/loggedInUserIssuesFiltersPreference",
      isLoadingIssueSearch                      : "issueSearch/isLoadingIssueSearch",
      issueSearch                               : "issueSearch/issueSearch",
      isIssueFieldsEnabled                      : "configurations/isIssueFieldsEnabled",
      isExtendedSearchEnabled                   : "configurations/isExtendedSearchEnabled",
      isTransitionDone                          : "transitions/isTransitionDone",
      isTransitionInProgress                    : "transitions/isTransitionInProgress",
      workflows                                 : "workflows/workflows",
      types                                     : "issueTypes/issueTypes",
      accesses                                  : "accesses/accesses",
      groupOfUsers                              : "users/groupsOfUsers",
      isTasksEnabled                            : "configurations/isTasksEnabled",
      isReporterIntakeFormsEnabled              : "configurations/isReporterIntakeFormsEnabled",
      workflowAssociations                      : "workflowAssociations/workflowAssociations",
      resolutions                               : "issueResolutions/issueResolutions",
      dataRetentionPeriods                      : "dataRetentionPeriods/dataRetentionPeriods",
      lastCreatedIssue                          : "transitions/lastCreatedIssue",
      loggedInUserIssuesColumnsSortingPreference: "users/loggedInUserIssuesColumnsSortingPreference",
      isUpdatingIssuesColumnsSortingPreference  : "users/isUpdatingIssuesColumnsSortingPreference",
      isIssueFormsEnabled                       : "configurations/isIssueFormsEnabled",
      issueFormTemplates                        : "formTemplates/issueFormTemplates",
      isCsvFileUploadedForBulkImportCases       : "issues/isCsvFileUploadedForBulkImportCases",
      isProgressBarInitiated                    : "shared/isProgressBarInitiated",
      allowedIssuesAndDomainsForIssueViewPolicy : "accessControl/allowedIssuesAndDomainsForIssueViewPolicy"
    }),
    activeDomains() {
      return this.domains.filter(domain => domain.archived === false).map(domain => {
        return {
          text             : domain.name,
          value            : domain.id,
          hasAccessToDomain: this.userHasAccessToTheDomain(domain.id)
        }
      })
    },
    activeDomainsToDisplay(){
      return this.activeDomains
    },
    sortedIssueIds() {
      return this.issues.map(issue => issue.id).sort((firstIssueId, secondIssueId) => firstIssueId - secondIssueId)
    },
    resolution() {
      return this.resolutions.find(resolution => resolution.id === this.issue?.resolutionId)
    },
    resolutionsToDisplay() {
      return this.resolutions.map(resolution => {
        return {
          value: resolution.id,
          text : resolution.name
        }
      })
    },
    labelsForSelection() {
      const labelsForSelection = [{
        header: this.$t("604")
      }]
      labelsForSelection.push(...this.labels.map(label => label.name))
      return labelsForSelection
    },
    usersWithUnassignedOption() {
      const usersWithDetails = []
      for (const user of this.users) {
        if (user?.enabled && user.id !== this.loggedInUser.id) {
          usersWithDetails.push(user)
        }
      }
      return [{
        ...this.loggedInUser,
        name: this.$t("951")
      }, ...usersWithDetails]
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod) =>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const dataRetentionOption of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(dataRetentionOption)
        dataRetentionPeriods.push({
          value: this.$moment().add(dataRetentionOption, "days").format("YYYY-MM-DD"),
          text : this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      return dataRetentionPeriods
    },
    loggedInUserGroupIds() {
      return this.groupOfUsers[this.loggedInUser.id]?.map(group => group.id)
    },
    caseWorkflowAssociation() {
      return this.workflowAssociations.find(association => association.issueTypeId === this.issueTypeCase.id)
    },
    caseWorkflow() {
      return this.workflows.find(workflow => workflow.id === this.caseWorkflowAssociation.workflowId)
    },
    initialTransitionCase() {
      return this.caseWorkflow.transitions.find(transition => transition.initialTransition)
    },
    issueTypeCase() {
      return this.types.find(type => type.id === ISSUE_TYPES[0].id)
    },
    latestIssuesExportStatus() {
      return this.latestIssuesExport?.status
    },
    isIssueFieldsExportInProgress() {
      return this.isAddingExport || this.isIssuesExportInitiated
    },
    isIssuesExportInitiated() {
      return this.latestIssuesExportStatus === DATA_EXPORT_STATUS.INITIATED
    },
    issueResolutionsMap() {
      const issueResolutionsMap = new Object()
      for (const issueResolution of this.issueResolutions) {
        issueResolutionsMap[issueResolution.id] = issueResolution
      }
      return issueResolutionsMap
    },
    issueStatusesMap() {
      const issueStatusesMap = new Object()
      for (const issueStatus of this.issueStatuses) {
        issueStatusesMap[issueStatus.id] = issueStatus
      }
      return issueStatusesMap
    },
    issueFieldMap() {
      const issueFieldMap = new Object()
      for (const issueField of this.issueFields) {
        issueFieldMap[issueField.id] = issueField
      }
      return issueFieldMap
    },
    issueFieldValuesMap() {
      const issueFieldValuesMap = new Object()
      for (const issueFieldValue of this.issueFieldValues) {
        if (!issueFieldValuesMap[issueFieldValue.issueId]) {
          issueFieldValuesMap[issueFieldValue.issueId] = []
        }
        issueFieldValuesMap[issueFieldValue.issueId].push(issueFieldValue)
      }
      return issueFieldValuesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    issuesForTable: function() {
      const result = this.filteredIssues.map(issue => {
        const user                   = this.usersMap[issue.assigneeId]
        const issueFieldValues       = this.issueFieldValuesMap[issue.id]
        let issueFieldValuesForTable = {}
        if (issueFieldValues) {
          issueFieldValuesForTable = issueFieldValues.reduce((accumulator, current) => {
            const issueField = this.issueFieldMap[current.issueFieldId]
            if (issueField) {
              const field = this.fieldsMap[issueField.fieldId]
              if (field) {
                switch (field.type) {
                  case FIELD_TYPES.DATE.value: {
                    accumulator[current.issueFieldId.toString()] = current.value ?
                      this.$moment(current.value).format("DD MMMM YYYY") : undefined
                    break
                  }
                  case FIELD_TYPES.DATE_TIME.value: {
                    accumulator[current.issueFieldId.toString()] = current.value ?
                      this.$moment(current.value).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined
                    break
                  }
                  case FIELD_TYPES.BOOLEAN.value: {
                    accumulator[current.issueFieldId.toString()] = current.value === "true" ? this.$t("1048") : this.$t("1049")
                    break
                  }
                  case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
                    if (current.value) {
                      if (!accumulator[current.issueFieldId.toString()]) {
                        accumulator[current.issueFieldId.toString()] = []
                      }
                      accumulator[current.issueFieldId.toString()].push(current.value)
                    }
                    break
                  }
                  default: {
                    accumulator[current.issueFieldId.toString()] = current.value
                    break
                  }
                }
              }
            }
            return accumulator
          }, {})
        }

        return {
          id               : issue.id,
          lastUpdated      : this.$moment(issue.updatedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          domain           : this.domains.find(domain => domain.id === issue.domainId)?.name,
          status           : this.issueStatusesMap[issue.statusId]?.name,
          assignee         : user?.name,
          enabled          : user?.enabled,
          labels           : issue.labels,
          closedOn         : issue.closedAt ? this.$moment(issue.closedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          acknowledgedOn   : issue.acknowledgedAt ? this.$moment(issue.acknowledgedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          receivedOn       : issue.receivedAt ? this.$moment(issue.receivedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          resolution       : this.issueResolutionsMap[issue.resolutionId]?.name,
          summary          : issue.summary,
          createdOn        : this.$moment(issue.createdAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          deletedAt        : user?.deletedAt,
          type             : issue.typeId,
          parentId         : issue.parentId,
          dataRetainedUntil: issue.dataRetainedUntil ? this.$moment(issue.dataRetainedUntil).format("DD MMMM YYYY") : undefined,
          dueDate          : issue.dueDate ? this.$moment(issue.dueDate).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          ...issueFieldValuesForTable
        }
      })

      return result
    },
    headersForTable() {
      const iconHeader      = this.$TABLES.ISSUES.headers[0]
      const idHeader        = this.$TABLES.ISSUES.headers[1]
      const headersForTable = [{
        ...iconHeader
      }, {
        ...idHeader,
        text: this.$t(idHeader.text)
      }]
      for (const issuesColumnPreference of this.loggedInUserIssuesColumnsPreference) {
        if (issuesColumnPreference.selected) {
          if (isNaN(issuesColumnPreference.column)) {
            const header = this.$TABLES.ISSUES.headers.find(item => item.value === issuesColumnPreference.column)
            headersForTable.push({
              ...header,
              text: this.$t(header.text)
            })
          } else {
            const issueField = this.issueFields.find(issueField => issueField.id === issuesColumnPreference.column)
            if (issueField) {
              const field = this.fieldsMap[issueField.fieldId]
              if (field) {
                switch (field.type) {
                  case FIELD_TYPES.DATE.value: {
                    headersForTable.push(this.$TABLES.ISSUES.dateColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.DATE_TIME.value: {
                    headersForTable.push(this.$TABLES.ISSUES.dateTimeColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.BOOLEAN.value: {
                    headersForTable.push(this.$TABLES.ISSUES.booleanColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
                    headersForTable.push(this.$TABLES.ISSUES.textColumn(field.systemName, issueField.id, false))
                    break
                  }
                  default: {
                    headersForTable.push(this.$TABLES.ISSUES.textColumn(field.systemName, issueField.id))
                    break
                  }
                }
              }
            }
          }
        }
      }
      return headersForTable
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ISSUES, this.$t.bind(this))
    },
    defaultSortByColumn() {
      return this.localIssuesSortingPreference.sortBy
    },
    defaultSortDesc() {
      return this.localIssuesSortingPreference.sortDesc
    },
    fieldsMap() {
      const fieldsMap = {}
      for  (const field of this.fields) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    formTemplatesMap() {
      const formTemplatesMap = {}
      for  (const formTemplate of this.formTemplates) {
        formTemplatesMap[formTemplate.id] = formTemplate
      }
      return formTemplatesMap
    },
    /**
     * We are filtering the filtered issues when there is a kpiFilterCriteria
     */
    filteredIssues() {
      let filteredIssues = this.lastSearchIssues

      if (this.kpiFilterCriteria.length) {
        filteredIssues = filteredIssues.filter(issue => {
          if (this.kpiFilterCriteria.includes(0) && !this.issuesWithUnreadMessages.includes(issue.id)) {
            return false
          }
          if (this.kpiFilterCriteria.includes(1) && !this.issuesWithUnansweredReport.includes(issue.id)) {
            return false
          }
          return true
        })
      }
      return filteredIssues
    },
    canAddIssue() {
      return !!this.loggedInUserPolicies["Issue add"]
    },
    canImportIssues() {
      return !!this.loggedInUserPolicies["BulkImport add"]
    },
    canExportIssues() {
      const exportAddPolicies = this.loggedInUserPolicies["Export add"]
      if (exportAddPolicies) {
        for (const exportAddPolicy of exportAddPolicies) {
          if (exportAddPolicy.set.type.includes("issues with selected fields")) {
            return true
          }
        }
      }
      return false
    },
    isUserIssuesFilterPreferenceChanged() {
      return JSON.stringify(this.loggedInUserIssuesFiltersPreference) !==
      JSON.stringify(this.localIssuesFiltersPreference)
    },
    isUserIssuesSortingPreferenceChanged() {
      return JSON.stringify(this.loggedInUserIssuesColumnsSortingPreference) !==
      JSON.stringify(this.localIssuesSortingPreference)
    },
    latestIssueSearchStatus() {
      return this.issueSearch?.status
    },
    isIssueSearchInitiated() {
      return this.latestIssueSearchStatus === ISSUE_SEARCH_STATUS.INITIATED
    },
    isIssueSearchCompleted() {
      return this.latestIssueSearchStatus === ISSUE_SEARCH_STATUS.COMPLETED
    },
    isSearchTextChanged() {
      return this.issueSearch?.criteria && this.searchText !== this.issueSearch.criteria.searchText
    },
    isSearchThroughAllIssueDataChanged() {
      return this.issueSearch?.criteria
      && this.searchThroughAllIssueData !== this.issueSearch?.criteria?.searchThroughAllIssueData
    },
    isSearchCriteriaChanged() {
      return this.isUserIssuesFilterPreferenceChanged
      || this.isSearchTextChanged
      || this.isSearchThroughAllIssueDataChanged
    },
    shouldPollForIssueSearch() {
      return this.isIssueSearchInitiated && !this.isLoadingIssueSearch && !this.issueSearchPollingInterval
    },
    advancedFilters() {
      const advancedFilters = [{
        header: this.$t("1246")
      }, {
        name : this.$t("2066"),
        value: this.$t("2066")
      }, {
        name : this.$t("227"),
        value: this.$t("227")
      }, {
        name : this.$t("292"),
        value: this.$t("292")
      }, {
        name : this.$t("291"),
        value: this.$t("291")
      }, {
        name : this.$t("1003"),
        value: this.$t("1003")
      }, {
        name : this.$t("694"),
        value: this.$t("694")
      }, {
        name : this.$t("224"),
        value: this.$t("224")
      }, {
        name : this.$t("1838"),
        value: this.$t("1838")
      }]

      if (this.issueFields && this.isIssueFieldsEnabled) {
        const filteredIssueFields = this.issueFields
          .filter(issueField => [
            FIELD_TYPES.OPTION_LIST.value,
            FIELD_TYPES.MULTIPLE_OPTION_LIST.value,
            FIELD_TYPES.BOOLEAN.value
          ]
            .includes(this.fieldsMap[issueField.fieldId]?.type)
          )
          .map(issueField => this.fieldsMap[issueField.fieldId])

        const uniqueFilteredIssueFields = [...new Set(filteredIssueFields)]
        uniqueFilteredIssueFields.forEach(field =>
          advancedFilters.push({
            name : field.systemName,
            value: `issue-fields-${field.systemName}`
          })
        )
      }

      advancedFilters.push(...[{
        header: this.$t("1247")
      }, {
        name : this.$t("285"),
        value: this.$t("285")
      }, {
        name : this.$t("1161"),
        value: this.$t("1161")
      }, {
        name : this.$t("631"),
        value: this.$t("631")
      }, {
        name : this.$t("1160"),
        value: this.$t("1160")
      }])

      if (this.formTemplateConfigurations && this.isReporterIntakeFormsEnabled) {
        const filteredReportIntakeFormTemplateConfigurations = this.formTemplateConfigurations.filter(
          formTemplateConfiguration =>
            this.formTemplates.some(
              formTemplate => formTemplate.id === formTemplateConfiguration.formTemplateId && formTemplate.reportForm
            )
        )
        const filteredFields                                 = filteredReportIntakeFormTemplateConfigurations
          .filter(formTemplateConfiguration =>
            this.formTemplatesMap[formTemplateConfiguration.formTemplateId] &&
            [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.BOOLEAN.value]
              .includes(this.fieldsMap[formTemplateConfiguration.fieldId].type))
          .map(formTemplateConfiguration => this.fieldsMap[formTemplateConfiguration.fieldId])

        const uniqueFilteredFields = [...new Set(filteredFields)]
        uniqueFilteredFields.forEach(field => advancedFilters.push({
          name : field.systemName,
          value: `reporter-intake-forms-${field.systemName}`
        }))
      }

      return advancedFilters
    },
    getActiveDomains() {
      return this.domains.filter(domain => !domain.archived)
    },
    caseStatusesMap( ) {
      const caseStatusesMap = new Object()
      for (const issueStatus of this.caseWorkflow.statuses) {
        caseStatusesMap[issueStatus.id] = issueStatus
      }
      return caseStatusesMap
    }
  },
  watch: {
    isTasksEnabled: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.issueTypes.push({
            header: this.$t("1273")
          },
          {
            name : ISSUE_TYPES[1].name,
            value: ISSUE_TYPES[1].id
          })
        }
      }
    },
    "$route.query.unanswered": {
      immediate: true,
      handler  : function(newValue) {
        if(newValue && !this.kpiFilterCriteria.includes(1)) {
          this.kpiFilterCriteria.push(1)
        }
      }
    },
    "$route.query.unread": {
      immediate: true,
      handler  : function(newValue) {
        if(newValue && !this.kpiFilterCriteria.includes(0)) {
          this.kpiFilterCriteria.push(0)
        }
      }
    },
    isUserIssuesFilterPreferenceChanged: {
      deep   : true,
      handler: function(newValue) {
        if (newValue) {
          if (!this.isLoadingIssues && this.isIssueSearchCompleted) {
            this.loadIssueSearchWithCriteria({
              criteria: {
                searchText               : this.searchText,
                issuesFilters            : this.localIssuesFiltersPreference,
                searchThroughAllIssueData: this.searchThroughAllIssueData
              }
            })
          }
        }
      }
    },
    loggedInUserIssuesColumnsSortingPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.localIssuesSortingPreference) {
          this.localIssuesSortingPreference = newValue
        }
      }
    },
    isUserIssuesSortingPreferenceChanged: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.isUpdatingIssuesColumnsSortingPreference) {
          this.updateUser({
            id                            : this.loggedInUser.id,
            issuesColumnsSortingPreference: this.localIssuesSortingPreference
          })
        }
      }
    },
    isIssuesColumnsPreferenceUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.showIssuesColumnsPreference = false
          this.loadIssuesFromLastSearch()
        }
      }
    },
    shouldPollForIssueSearch: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.issueSearchPollingInterval = setInterval(() => {
            this.loadIssueSearch({ id: this.issueSearch.id })
          }, ISSUE_SEARCH_POLL_INTERVAL)
        }
      }
    },
    latestIssueSearchStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === ISSUE_SEARCH_STATUS.COMPLETED) {
          this.clearIssueSearchPollingInterval()
          this.loadIssuesFromLastSearch()
        }
      }
    },
    isLoadingIssues: {
      immediate: true,
      handler  : function(newValue) {
        if (
          !newValue
          && this.isIssueSearchCompleted
          && this.isSearchCriteriaChanged
          && !this.isLoadingIssueSearch
        ) {
          this.loadIssueSearchWithCriteria({
            criteria: {
              searchText               : this.searchText,
              issuesFilters            : this.localIssuesFiltersPreference,
              searchThroughAllIssueData: this.searchThroughAllIssueData
            }
          })
        }
      }
    },
    isTransitionDone: {
      handler: function(newValue) {
        if (newValue) {
          this.showAddIssueDialog = false
          if (this.domainId && this.userHasAccessToTheDomain(this.domainId)) {
            this.$router.push({ name: "issue", params: { id: this.lastCreatedIssue } })
            this.domainId = null
          }
        }
      }
    },
    isCsvFileUploadedForBulkImportCases: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1873"
          })
        }
      }
    },
    lastSearchIssues: {
      handler: function(newValue) {
        if (newValue && !this.isProgressBarInitiated) {
          this.setProgressBarPromisesPending(false)
        }
      }
    }
  }
}