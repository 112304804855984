import ThemisInput from "@/components/shared/input"
import ThemisSpeakUpFieldsValidationTable from "@/components/speak-up-fields-validation-table"
import ThemisValidationStatusIcons from "@/components/shared/validation-status-icons"
import { sortIndices } from "@/utils"
import csv from "csvtojson"
import {
  BOOLEAN,
  IMPORT_CASES_DOCUMENT_TYPE,
  KEYS_FOR_BULK_IMPORT,
  SPEAK_UP_FIELDS_ITEMS,
  BULK_IMPORT_ISSUE_PROPERTIES_ARRAY
} from "@/constants"
export default {
  name      : "BulkImportCases",
  components: {
    ThemisInput,
    ThemisValidationStatusIcons,
    ThemisSpeakUpFieldsValidationTable
  },
  props: {
    pDomains                   : Array,
    pIssueFields               : Array,
    pFormTemplateConfigurations: Array,
    pIssueFormTemplates        : Array,
    pItemsForTable             : Array,
    pOptionListItems           : Array,
    pIsIssueFormsEnabled       : Boolean,
    pIsIssueFieldsEnabled      : Boolean,
    pFieldsMap                 : Object,
    pIssueResolutionsMap       : Object,
    pCaseStatusesMap           : Object
  },
  emits: ["cancel", "speakupFieldMappingStatus"],
  data : () => ({
    currentImportCasesStep   : 1,
    selectedDomain           : null,
    csvContent               : null,
    uploadedFile             : null,
    firstRowAsHeader         : true,
    isStepThreeDisabled      : true,
    validationSuccess        : false,
    validationFailure        : false,
    validationInProgress     : false,
    itemsForCsvDataValidation: [],
    jsonArray                : [],
    headersArray             : [],
    errorToBeDisplayed       : {},
    speakupFieldsHeadersMap  : new Map()
  }),
  methods: {
    goToNextImportCasesStep(currentStep) {
      if (currentStep === 2) {
        this.itemsForCsvDataValidation = this.restructureCsvDataForTable(this.jsonArray)
      }
      this.currentImportCasesStep = currentStep + 1
    },
    goToPreviousImportCasesStep(currentStep) {
      if (currentStep === 2) {
        this.resetChangesOfStepTwo()
      } else if (currentStep === 3) {
        this.resetChangesOfStepThree()
      }
      this.currentImportCasesStep = currentStep - 1
    },
    resetChangesOfBulkImport() {
      this.showBulkImportCasesDialog = false
      this.selectedDomain            = null
      this.firstRowAsHeader          = true
      this.currentImportCasesStep    = 1
      this.uploadedFile              = null
      this.isStepThreeDisabled       = true
      this.speakupFieldsHeadersMap   = new Map()
    },
    resetChangesOfStepTwo() {
      this.uploadedFile              = null
      this.firstRowAsHeader          = true
      this.validationSuccess         = false
      this.validationFailure         = false
      this.validationInProgress      = false
      this.itemsForCsvDataValidation = []
    },
    resetChangesOfStepThree() {
      this.speakupFieldsHeadersMap = new Map()
      this.isStepThreeDisabled     = true
    },
    resetValidationStatus() {
      this.headersArray         = []
      this.validationSuccess    = false
      this.validationFailure    = false
      this.validationInProgress = false
      this.errorToBeDisplayed   = {}
    },
    cancelImportCases() {
      this.resetChangesOfBulkImport()
      this.resetValidationStatus()
      this.$emit("cancel")
    },
    openFileExplorerToSelectImportCasesDocument() {
      this.$refs.input_csv_file.click()
    },
    async onImportCasesDocumentSelection(event) {
      this.validationInProgress = true
      this.uploadedFile         = event.target.files[0]
      this.csvContent           = await this.readFileAsText(this.uploadedFile)
      this.jsonArray            = await this.csvToJson(this.csvContent)

      if (this.firstRowAsHeader) {
        const emptyIndicesArray = this.getEmptyHeaderIndices(this.headersArray)
        if (emptyIndicesArray.length) {
          this.csvHeaderValidationFailure("1951", emptyIndicesArray)
          return
        }
        const duplicateHeaderIndices = this.getDuplicateHeaderIndices(this.headersArray)
        if (duplicateHeaderIndices.length) {
          this.csvHeaderValidationFailure("1950", duplicateHeaderIndices)
          return
        }
      } else {
        this.headersArray =  Object.keys(this.jsonArray[0])
      }
      const mismatchedIndices = this.validateCsvData(this.jsonArray)
      if (mismatchedIndices.length) {
        this.csvHeaderValidationFailure("1952", mismatchedIndices)
        return
      }
      this.csvHeaderValidationSuccess()
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload  = event => resolve(event.target.result)
        reader.onerror = event => reject(event) //file read error

        reader.readAsText(file)
      })
    },
    async csvToJson(csvContent) {
      const csvParser = csv({
        delimiter: "auto",
        noheader : !this.firstRowAsHeader
      })
      if (this.firstRowAsHeader) {
        csvParser.on("header", headers => {
          headers.forEach(header => {
            this.headersArray.push(header)
          })
        })
      }
      return await csvParser.fromString(csvContent)
    },
    getEmptyHeaderIndices(headers) {
      const indicesArray = []
      headers.forEach((header, index) => {
        if (header === "") {
          indicesArray.push(index + 1)
        }
      })
      return indicesArray
    },
    getDuplicateHeaderIndices(headers) {
      const indicesArray = []
      const headerSet    = new Set()
      headers.forEach((header, index) => {
        if (headerSet.has(header)) {
          indicesArray.push(index + 1)
        }
        headerSet.add(header)
      })
      return indicesArray
    },
    validateCsvData(jsonArray) {
      const indicesArray = []
      jsonArray.forEach((row, index) => {
        const rowKeys = Object.keys(row)
        if (rowKeys.length !== this.headersArray.length) {
          indicesArray.push(index + 1)
        }
      })
      return indicesArray
    },
    csvHeaderValidationFailure(message, indices) {
      this.validationFailure    = true
      this.validationInProgress = false
      this.validationSuccess    = false
      this.errorToBeDisplayed   = {
        message      : message,
        indicesLength: indices.length,
        sortedIndices: sortIndices(indices)
      }
    },
    csvHeaderValidationSuccess() {
      this.validationFailure    = false
      this.validationInProgress = false
      this.validationSuccess    = true
    },
    restructureCsvDataForTable(jsonArray) {
      if (jsonArray.length > 0) {
        const headers = this.firstRowAsHeader ?
          this.headersArray : this.headersArray.map((_, index) => String(index + 1))

        const rows       = jsonArray.map(row => Object.values(row))
        const exampleRow =  rows[0]

        const transposedRows = rows[0].map((_, colIndex) => rows.map(row => row[colIndex]))

        return headers.map((header, index) => ({
          header,
          example         : exampleRow[index],
          headerColumnRows: transposedRows[index]
        }))

      } else {
        return []
      }
    },
    handleRemoveUploadedCsvFile() {
      this.resetValidationStatus()
      this.uploadedFile = null
    },
    getValidationRules() {
      return this.validationFailure ?
      `speakup_fields_error:true,${this.errorToBeDisplayed.message},${this.errorToBeDisplayed.sortedIndices}` : "speakup_fields_error:false"
    },
    handleSpeakUpFieldMapping(item) {
      this.speakupFieldsHeadersMap.set(item.header, item)
      this.isStepThreeDisabled = this.validateStepThree()
    },
    validateStepThree() {
      if (!this.speakupFieldsHeadersMap) {
        return true
      }

      const mandatoryFields = [SPEAK_UP_FIELDS_ITEMS.CREATED_AT, SPEAK_UP_FIELDS_ITEMS.STATUS_ID]
      const itemValues      = Array.from(this.speakupFieldsHeadersMap.values())
      const hasNoError      = itemValues.every(value => value.error === BOOLEAN.FALSE)
      if (!hasNoError) {
        return true
      }

      const allFieldsPresent = mandatoryFields.every(field =>
        itemValues.some(value => value.mappedSpeakupField === field && value.error === BOOLEAN.FALSE)
      )
      return !allFieldsPresent
    },
    handleImportCases() {
      const speakupFieldsMap     = Array.from(this.speakupFieldsHeadersMap.values())
      const speakupFieldsMapping = speakupFieldsMap
        .filter(speakupFieldMap => speakupFieldMap.mappedSpeakupField !== SPEAK_UP_FIELDS_ITEMS.SKIP_IN_IMPORT)
        .map(speakupFieldMap => {
          const { mappedSpeakupField, header } = speakupFieldMap
          if (mappedSpeakupField.startsWith("form-template-configuration-id-")) {
            const formTemplateConfigurationId = mappedSpeakupField.slice("form-template-configuration-id-".length)
            return {
              header,
              key                        : KEYS_FOR_BULK_IMPORT.ISSUE_FORM,
              formTemplateConfigurationId: +formTemplateConfigurationId
            }
          } else if (mappedSpeakupField.startsWith("issue-field-id-")) {
            const issueFieldId = mappedSpeakupField.slice("issue-field-id-".length)
            return {
              header,
              key         : KEYS_FOR_BULK_IMPORT.ISSUE_FIELD,
              issueFieldId: +issueFieldId
            }
          } else if (BULK_IMPORT_ISSUE_PROPERTIES_ARRAY.includes(mappedSpeakupField)) {
            return {
              header,
              key: mappedSpeakupField
            }
          }
        })
      this.$emit("updateBulkImportCases", {
        name            : this.uploadedFile.name,
        file            : this.uploadedFile,
        domainId        : this.selectedDomain,
        firstRowIsHeader: this.firstRowAsHeader,
        speakupFieldsMapping
      })
      this.resetChangesOfBulkImport()
    }
  },
  computed: {
    firsRowAsHeaderLabelClass() {
      return this.uploadedFile ? "grey--text lighten-5" : "black--text body-2"
    },
    stepsForImportCases() {
      return [{
        stepNumber: 1,
        label     : this.$t("1870")
      }, {
        stepNumber: 2,
        label     : this.$t("1871")
      }, {
        stepNumber: 3,
        label     : this.$t("1872")
      }]
    },
    allowedImportCasesDocumentTypes() {
      return Object.values(IMPORT_CASES_DOCUMENT_TYPE).toString()
    },
    isNextStepDisabled() {
      if (this.currentImportCasesStep === 1) {
        return !this.selectedDomain
      } else if (this.currentImportCasesStep === 2) {
        return !this.uploadedFile || this.validationInProgress || !!Object.keys(this.errorToBeDisplayed).length
      }
      return false
    },
    isStartImportDisabled() {
      if (this.currentImportCasesStep === 3) {
        return this.isStepThreeDisabled
      }
      return false
    }
  }
}